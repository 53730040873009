import React from 'react';
import pageStyle from '../../../assets/css/page.module.css';
import style from '../../../assets/css/main.module.css';
import Menu from '../../../components/menu';
import Footer from '../../../components/footer';
import HeroPage from '../../../components/hero.page';
import Hamburger from '../../../components/hamburger';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Pricing from '../../../components/pricing';
import PricingText from '../../../components/pricing.text';

import imgSEO from '../../../assets/img/heroes/website_creation.jpg';
import SimilarCard from '../../../components/similar.card';
import similar2 from '../../../assets/img/heroes/thumbnails/seo.jpg';
import similar3 from '../../../assets/img/heroes/thumbnails/optimisation.jpg';
import similar4 from '../../../assets/img/heroes/thumbnails/social2.jpg';
import SEO from '../../../components/seo';
import CtaSection from '../../../components/cta.section';
import cta from '../../../assets/img/illustrations/questions.svg';
import contentPic from '../../../assets/img/illustrations/website_setup.svg';
import contentPic2 from '../../../assets/img/illustrations/experience_design.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Création de sites web | Agence digitale | MDS Digital Agency"
      description="MDS Digital Agency est une agence digitale spécialisée dans la création de site internet. Nous créons des sites internet sur mesure ou à partir d’un thème prédéfini"
      keywords="création de sites internet"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2 className={pageStyle.header_uppercase}>Création de site web</h2>
      <div className={pageStyle.intro_container} style={{ marginTop: '3em', marginBottom: '6em' }}>
        <h4 className={pageStyle.intro}>
          Vous souhaitez être présent sur le web ? Créez un site internet qui vous ressemble afin de
          laisser votre empreinte sur le web. Nous surfons sur les dernières tendances et ne perdons
          jamais de vue l’expérience utilisateur.
        </h4>
      </div>
      <Grid style={{ marginBottom: '6em', textAlign: 'right' }}>
        <Row>
          <Col md={12} className={pageStyle.flex_v_align}>
            <div>
              <h2 id="sur-mesure" className={pageStyle.subtitle} style={{ textAlign: 'left' }}>
                1. Site internet à partir d’un thème prédéfini
              </h2>
              <img
                src={contentPic}
                alt=""
                style={{
                  width: '30%',
                  float: 'right',
                  display: 'inline-block',
                  marginLeft: '30px',
                  marginTop: '30px'
                }}
              />
              <p>
                Vous désirez avoir un site internet où on peut retrouver toutes les informations
                utiles de votre entreprise ?
              </p>
              <p>
                MDS Digital Agency conçoit votre site internet à partir d’un thème prédéfini. Vous
                pouvez choisir votre design de base parmi plusieurs thèmes et nous l’adaptons aux
                couleurs de votre entreprise. Ces thèmes sont modifiables mais pour des
                modifications plus techniques, partez plutôt pour notre offre sur mesure.
              </p>

              <p>
                Nous décidons ensemble du contenu de votre site web. Il sera conçu sous Wordpress.
                Si vous désirez un thème Wordpress plus avancé, un autre CMS, un autre langage pour
                votre site ou des modifications plus techniques, dirigez-vous alors vers notre offre
                de site sur mesure.
              </p>
              <p>
                Wordpress possède une interface assez facile de prise en main, nous pouvons vous
                former à son utilisation pour que vous puissiez ajouter du contenu sur votre site
                par la suite. Si toutefois vous préférez ne vous occuper de rien, sachez que nous
                sommes spécialisés en gestion de contenu et notre équipe se chargera de la gestion
                quotidienne de votre site.
              </p>
              <p>
                En fonction de votre secteur d’activité, vous pouvez choisir entre un site vitrine
                et un site e-commerce. Entre présenter vos services ou bien vendre vos produits en
                ligne, c’est vous qui choisissez.
              </p>
              <p>
                Afin d’être visible sur les moteurs de recherche, le référencement de base est
                compris pour votre site.
              </p>
              <p>
                Un des gros avantages de cette formule, c’est que la création du site internet est
                plus rapide et elle coûte moins cher.
              </p>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '40px' }}>
          <Col md={12} className={pageStyle.flex_v_align}>
            <div>
              <h2 id="sur-mesure" className={pageStyle.subtitle} style={{ textAlign: 'right' }}>
                2. Site internet sur mesure
              </h2>
              <img
                src={contentPic2}
                alt=""
                style={{
                  width: '30%',
                  float: 'left',
                  display: 'inline-block',
                  margin: '30px'
                }}
              />
              <p>
                Vous désirez un site internet à l’image de votre marque ? Optez pour nos sites sur
                mesure.
              </p>
              <p>
                MDS Digital Agency est spécialisé dans la création de site internet. Nous vous
                accompagnons tout au long de votre projet. Du design, en passant par les différentes
                fonctionnalités à apporter à votre site internet, nous vous écoutons pour que votre
                site vous ressemble.
              </p>
              <p>
                Choisir un site internet sur mesure, c’est profiter de nombreux avantages.
                Premièrement, le choix de la technologie utilisée pour la création du site internet
                est totalement libre. Nous définissons ensemble les objectifs de votre site pour
                qu’il vous convienne parfaitement. Deuxièmement, le design est au cœur du projet.
                Nous le travaillons selon vos goûts et vos envies. Et enfin, au niveau des
                fonctionnalités, nous choisissons celles à ajouter sur votre site web. En fonction
                de votre secteur d’activité, vous pouvez choisir entre un site vitrine et un site
                e-commerce. Entre présenter vos services ou bien vendre vos produits en ligne, c’est
                vous qui choisissez.
              </p>
            </div>
          </Col>
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Col md={6}>
            <Pricing title="WORDPRESS">
              <PricingText>7 pages maximum</PricingText>
              <PricingText>Référencement de base</PricingText>
              <PricingText>Fonctionnalités limitées</PricingText>
              <PricingText>Design au choix parmi nos thèmes prédéfinis</PricingText>
            </Pricing>
          </Col>
          <Col md={6}>
            <Pricing title="SUR MESURE">
              <PricingText>Nombre de pages illimité</PricingText>
              <PricingText>Référencement approfondi</PricingText>
              <PricingText>Fonctionnalités au choix</PricingText>
              <PricingText>Design sur mesure</PricingText>
            </Pricing>
          </Col>
        </Row>
      </Grid>

      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Combien ça coûte ? Combien de temps prendra l'élaboration de mon site ?"
        subtitle="Ne restez plus sans réponse"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Référencement" url="/services/website/referencement/" img={similar2} />
          <SimilarCard
            text="Optimisation de sites web"
            url="/services/website/optimisation/"
            img={similar3}
          />
          <SimilarCard
            text="Gestion de contenu"
            url="/services/website/gestion-de-contenu/"
            img={similar4}
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
